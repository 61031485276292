import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {UserWebservice} from '../../../../services/webservices/user.webservice';
import {DeletedUserDTO} from 'src/app/model/dto/deleted-user.dto';
import {AlertService} from 'src/app/services/alert.service';
import {AlertComponent} from '../../alert/alert/alert.component';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-confirm-delete-user',
  templateUrl: './dialog-confirm-delete-user.component.html',
  styleUrls: ['./dialog-confirm-delete-user.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogTitle, MatDialogContent, AlertComponent, MatDialogActions]
})
export class DialogConfirmDeleteUserComponent implements OnInit, OnDestroy {
  result;
  id: number;
  isDeleted: boolean = null;
  isValidationDeleted = false;
  validationTotalDelete = false;
  validationAnonDelete = false;

  ALERT_DIALOG_DELETE_USER = 'alert-dialog-delete-user';
  @Input() idUser: number;
  @Input() title: string;
  @Input() message: string;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteUserComponent>,
    private readonly userWS: UserWebservice,
    private readonly router: Router,
    private readonly alertService: AlertService
  ) {}

  ngOnInit() {
    this.id = this.idUser;
  }

  Fermer() {
    this.result = true;
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }

  choix1() {
    // Suppresion totale du jeune
    this.userWS.deleteUser(this.idUser, true).subscribe(
      (res: DeletedUserDTO) => {
        if (res.needValidation) {
          this.isValidationDeleted = true;
          this.validationAnonDelete = false;
          this.validationTotalDelete = true;
          this.alertService.clear();
          this.alertService.error(res.message, this.ALERT_DIALOG_DELETE_USER);
        } else {
          if (res.hasDeletedUser) {
            this.result = true;
            this.dialogRef.close();
            this.router.navigate(['profile-young']);
          } else {
            this.result = false;
            this.alertService.clear();
            this.alertService.error(res.message, this.ALERT_DIALOG_DELETE_USER);
          }
        }
      },
      (err) => {
        this.result = false;
        this.alertService.clear();
        this.alertService.error(
          'Une erreur est survenue lors de la suppression.',
          this.ALERT_DIALOG_DELETE_USER
        );
      }
    );
  }

  validationChoix1() {
    // Validation si la suppression en declanche plusieurs autres
    this.userWS.deleteUser(this.idUser, true).subscribe(
      (res: DeletedUserDTO) => {
        if (res.hasDeletedUser) {
          this.result = true;
          this.dialogRef.close();
          this.router.navigate(['profile-young']);
        } else {
          this.alertService.clear();
          this.alertService.error(res.message, this.ALERT_DIALOG_DELETE_USER);
          this.isValidationDeleted = false;
          this.validationTotalDelete = false;
          this.validationAnonDelete = false;
        }
      },
      (err) => {
        this.result = false;
        this.alertService.clear();
        this.alertService.error(
          'Une erreur est survenue lors de la suppression.',
          this.ALERT_DIALOG_DELETE_USER
        );
        this.isValidationDeleted = false;
        this.validationTotalDelete = false;
        this.validationAnonDelete = false;
      }
    );
  }

  choix2() {
    // Anonymisation
    this.userWS.deleteUser(this.idUser, false).subscribe({
      next: (res: DeletedUserDTO) => {
        if (res.needValidation) {
          this.isValidationDeleted = true;
          this.validationAnonDelete = true;
          this.validationTotalDelete = false;
          this.alertService.clear();
          this.alertService.error(res.message);
        } else {
          if (res.hasDeletedUser) {
            this.result = true;
            this.dialogRef.close();
            this.router.navigate(['profile-young']);
          } else {
            this.alertService.clear();
            this.alertService.error(res.message, this.ALERT_DIALOG_DELETE_USER);
          }
        }
      },
      error: () => {
        this.result = false;
        this.alertService.clear();
        this.alertService.error(
          'Une erreur est survenue lors de la suppression.',
          this.ALERT_DIALOG_DELETE_USER
        );
      }
    });
  }

  validationChoix2() {
    this.userWS.deleteUser(this.idUser, false).subscribe(
      (res: DeletedUserDTO) => {
        if (res.hasDeletedUser) {
          this.result = true;
          this.dialogRef.close();
          this.router.navigate(['profile-young']);
        } else {
          this.alertService.clear();
          this.alertService.error(res.message, this.ALERT_DIALOG_DELETE_USER);
          this.isValidationDeleted = false;
          this.validationTotalDelete = false;
          this.validationAnonDelete = false;
        }
      },
      () => {
        this.result = false;
        this.alertService.clear();
        this.alertService.error(
          'Une erreur est survenue lors de la suppression.',
          this.ALERT_DIALOG_DELETE_USER
        );
      }
    );
  }
}
