<div class="dialog-default">
  <span *ngIf="title" class="important-text">{{title}}</span>
    <ng-container *ngIf="messages">
      <ng-container *ngFor="let message of messages">
        <hr>
        <p class="tiny-text">{{message.tab}}</p>
        <ul>
          <li *ngFor="let error of message.errors">{{error}}</li>
        </ul>
      </ng-container>
    </ng-container>
  <div class="d-flex flex-row justify-content-center">
    <button (click)="close()" class="btn btn-primary my-2 mx-2" type="button">Fermer</button>
  </div>
</div>

