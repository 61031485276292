<div class="dialog-default d-flex flex-column">
  <h1 *ngIf="title" mat-dialog-title>{{title}}</h1>
  <div mat-dialog-content>
    <p *ngIf="message">{{message}}</p>
    <p> • Suppression intégrale du jeune (y compris dans ses présences, si c'est un doublon)</p>
    <p> • Suppression anonymisée qui ne supprime que les informations sur le jeune et non ses présences et autres
      expériences</p>
    <app-alert id="alert-dialog-delete-user"></app-alert>
  </div>

  <div *ngIf="!isValidationDeleted" align="end" mat-dialog-actions>
    <button (click)="choix1()" class="btn btn-danger my-2 mx-2" type="button">Supprimer intégralement</button>
    <button (click)="choix2()" class="btn btn-primary my-2 mx-2" type="button">Suppression anonymisée</button>
    <button (click)="Fermer()" class="btn btn-warning my-2 mx-2" type="button">Fermer</button>
  </div>
  <div *ngIf="isValidationDeleted" align="end" mat-dialog-actions>
    <button (click)="validationChoix1()" class="btn btn-danger my-2 mx-2" type="button">Confirmer la suppression
      totale
    </button>
    <button (click)="validationChoix2()" class="btn btn-primary my-2 mx-2" type="button">Confirmer la suppression
      anonymisée
    </button>
    <button (click)="Fermer()" class="btn btn-warning my-2 mx-2" type="button">Annuler</button>
  </div>
</div>
