import {Component, OnDestroy} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {NgFor, NgIf} from '@angular/common';
import {ErrorInscriptionDto} from '../../../../model/error-inscription.dto';

@Component({
  selector: 'app-dialog-inscription-errors',
  templateUrl: './dialog-inscription-errors.component.html',
  styleUrls: ['./dialog-inscription-errors.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor]
})
export class DialogInscriptionErrorsComponent implements OnDestroy {
  result;

  title: string;
  messages: ErrorInscriptionDto[];

  constructor(public dialogRef: MatDialogRef<DialogInscriptionErrorsComponent>) {}

  close() {
    this.result = true;
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
}
