import {WorkDurationEnum} from '../enums/work-duration.enum';
import {Young} from '../user/young.model';
import {WorkExperience} from '../user/work-experience.model';
import {PStatutChangeMotif} from '../param/p-statut-change-motif.model';
import {PStatutSituationComplement} from '../param/p-statut-situation-complement.model';
import {MonitoringInterestField} from './monitoring-interest-field.model';
import {PMonitoringStatus} from '../param/p-monitoring-status.model';
import {PSituationAtDate} from '../param/p-situation-at-date.model';

export class Monitoring {
  id: number;
  idYoung: number;
  idPStatutChangeMotif?: number;
  idPMonitoringStatus?: number;
  dateMonitoringChange?: Date;
  idPSituationAtDate?: number;
  idPStatutSituationComplement?: number;
  idWorkExperience?: number;
  comment: string;
  isOutQuestionnaireDone?: boolean;
  establishedContact?: boolean;
  workDuration?: WorkDurationEnum;
  dateEnd?: Date;

  young: Young;
  pStatutChangeMotif: PStatutChangeMotif;
  workExperience: WorkExperience;
  pMonitoringStatus: PMonitoringStatus;
  pStatutSituationComplement: PStatutSituationComplement;
  listOfMonitoringInterestField: MonitoringInterestField[];
  pSituationAtDate: PSituationAtDate;
}
