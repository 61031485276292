import {Component} from '@angular/core';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-check-rgpd-contact',
  templateUrl: './dialog-check-rgpd-contact.component.html',
  styleUrls: ['./dialog-check-rgpd-contact.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogTitle, MatDialogActions, MatDialogContent]
})
export class DialogCheckRgpdContactComponent {
  hasValidDates = true;

  constructor(public dialogRef: MatDialogRef<DialogCheckRgpdContactComponent>) {}

  close() {
    this.dialogRef.close();
  }
}
