import {Young} from './young.model';
import {JobbeurNeed} from './jobbeur-need.model';
import {JobbeurAccompaniment} from './jobbeur-accompaniment.model';
import {PillarJobbeur} from './jobbeur-pillar.model';
import {JobbeurInterestField} from './jobbeur-interest-field.model';
import {JobbeurTravel} from './jobbeur-travel.model';
import {JobbeurProject} from './jobbeur-project.model';
import {SexeSponsorEnum} from '../enums/sexe.enum';
import {ScolarshipStatusEnum} from '../enums/scolarship-status.enum';
import {HighestLevelEnum} from '../enums/highest-level.enum';
import {JobbeurPDomaine} from './jobbeur-need-p-domaine.model';
import {JobbeurFinancingMechanism} from './jobbeur-financing-mechanism.model';

export class Jobbeur {
  id: number;
  expectedProfessionnalProject: string;
  sponsorWish: boolean;
  sponsorSex?: SexeSponsorEnum;
  scolarshipStatus?: ScolarshipStatusEnum;
  endSchoolDate?: Date;
  highestDiploma?: HighestLevelEnum;
  highestDiplomaDate?: Date;
  hasJobbeurNeed: boolean;

  young: Young;
  listOfPillarJobbeur: PillarJobbeur[];
  listOfJobbeurNeed: JobbeurNeed[];
  listOfJobbeurAccompaniment: JobbeurAccompaniment[];
  listOfJobbeurInterestField: JobbeurInterestField[];
  listOfJobbeurTravel: JobbeurTravel[];
  listOfJobbeurProject: JobbeurProject[];
  listOfJobbeurDomainMetier: JobbeurPDomaine[];
  listOfJobbeurFinancingMechanisms: JobbeurFinancingMechanism[];
}
