import {Program} from './program.model';
import {Year} from '../user/year.model';
import {Parcours} from './parcours/parcours.model';

export class RegisteredProgram {
  id: number;
  idYears: number;
  idProgram: number;
  registeredDate: Date;
  dateCreate: Date;
  dateUpdate: Date;

  years: Year;
  program: Program;
  listOfParcours: Parcours[];
}
