<div class="dialog-default d-flex flex-column p-4">
  <div class="d-flex flex-row w-100 mb-1">
    <span class="dialog-title" >
      Nous avons trouvé {{duplicates.length > 1 ? 'plusieurs jeunes' : 'un jeune'}} avec les mêmes informations :
    </span>
    <span class="flex-fill separator"></span>
    <button (click)="abortAction()"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <p class="my-0">Il est possible que le jeune que vous souhaitez inscrire existe déjà.</p>
  <p class="my-0">Si c'est le cas, sélectionnez le jeune concerné :</p>
  <div class="radio-group flex-fill list-youngs pt-2">
    <div *ngFor="let duplicate of duplicates" class="custom-control custom-radio">
      <input
        [(ngModel)]="result"
        [value]="duplicate.id"
        class="custom-control-input"
        id="{{'radio' + duplicate.id}}"
        type="radio">
      <label class="custom-control-label" for="{{'radio' + duplicate.id}}">{{duplicate.name}}</label>
    </div>
  </div>
  <div class="row align-items-center justify-content-end">
    <button (click)="exit()" class="btn btn-primary mr-5">Valider</button>
  </div>
  <div class="row align-items-center justify-content-end create-young">
    <p class="m-0">S'il n'est pas présent dans la liste : </p>
    <button (click)="createYoung()" class="mr-3" mat-button>Créer un jeune</button>
  </div>
  <div class="row align-items-center justify-content-end create-young" *ngIf="isEdit">
    <p class="m-0">Sinon vous pouvez continuer de le modifier : </p>
    <button (click)="abortAction()" class="mr-3" mat-button>Modifier</button>
  </div>
</div>


