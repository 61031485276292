<div class="dialog-default d-flex flex-column p-4">
  <div class="d-flex flex-row m-2">
    <span class="dialog-title">
      Nous avons trouvé {{duplicates.length}} responsable{{duplicates.length > 1 ? 's' : ''}}
      leg{{duplicates.length > 1 ? 'aux' : 'al'}}
      avec les mêmes informations
    </span>
    <span class="flex-fill separator"></span>
    <button (click)="abortAction()"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <p class="my-0">Il est possible que ce responsable légal existe déjà.</p>
  <p class="my-0">Si c'est le cas, sélectionnez le :</p>
  <div class="radio-group my-3 flex-fill">
    <div *ngFor="let duplicate of duplicates" class="custom-control custom-radio">
      <input
        [(ngModel)]="result"
        [value]="duplicate.user"
        class="custom-control-input"
        id="{{'radio' + duplicate.id}}"
        type="radio">
      <label class="custom-control-label" for="{{'radio' + duplicate.id}}">{{duplicate.name}}</label>
    </div>
  </div>
  <div class="my-md-3 my-5 row align-items-center justify-content-end">
    <button (click)="exit()" class="btn btn-primary mr-5">Fusionner</button>
  </div>
  <div class="row align-items-center justify-content-end create-young">
    <p class="m-0">S'il n'est pas présent dans la liste : </p>
    <button (click)="abortAction()" class="mr-3" mat-button>Annuler la fusion</button>
  </div>
</div>
