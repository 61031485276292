import {RegisteredProgram} from '../registered-program.model';
import {Booster} from './booster.model';
import {PParcours} from '../../param/p-parcours.model';

export class Parcours {
  id: number;
  idRegisteredProgram: number;
  idPParcours: number;
  dateBegin: Date;
  dateEnd?: Date;

  pParcours: PParcours;
  registeredProgram: RegisteredProgram;
  listOfBooster: Booster[];
}
