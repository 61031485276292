import {MatDialog} from '@angular/material/dialog';
import {DialogDefaultComponent} from '../components/shared/dialog/dialog-default/dialog-default.component';
import {FormSelectItem} from '../model/form/select-item.model';
import {DialogSelectYoungCreationDuplicateComponent} from '../components/shared/dialog/dialog-select-young-creation-duplicate/dialog-select-young-creation-duplicate.component';
import {DialogSelectYoungCreationFamilyComponent} from '../components/shared/dialog/dialog-select-young-creation-family/dialog-select-young-creation-family.component';
import {DialogConfirmComponent} from '../components/shared/dialog/dialog-confirm/dialog-confirm.component';
import {User} from '../model/user/user.model';
import {Injectable} from '@angular/core';
import {DialogShowDocsComponent} from '../components/shared/dialog/dialog-show-docs/dialog-show-docs.component';
import {DialogConfirmDeleteUserComponent} from '../components/shared/dialog/dialog-confirm-delete-user/dialog-confirm-delete-user.component';
import {DialogConfirmDeleteUserSdlvComponent} from '../components/shared/dialog/dialog-confirm-delete-user-sdlv/dialog-confirm-delete-user-sdlv.component';
import {DialogSelectRespLegalComponent} from '../components/shared/dialog/dialog-select-resp-legal/dialog-select-resp-legal.component';
import {DialogAttestationComponent} from '../components/shared/dialog/dialog-attestation/dialog-attestation.component';
import {DialogInscriptionErrorsComponent} from '../components/shared/dialog/dialog-inscription-errors/dialog-inscription-errors.component';
import {DialogCheckRgpdContactComponent} from '../components/shared/dialog/dialog-check-rgpd-contact/dialog-check-rgpd-contact.component';
import {TuteursYoung} from '../model/user/tuteur.model';
import {Destroyed} from '../components/shared/directives/destroyed.directive';
import {ErrorInscriptionDto} from '../model/error-inscription.dto';

@Injectable({
  providedIn: 'root'
})
export class DialogService extends Destroyed {
  constructor(private readonly dialog: MatDialog) {
    super();
  }

  confirm(
    title: string = undefined,
    message: string = undefined,
    yes: string = undefined,
    no: string = undefined
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DialogDefaultComponent);

      dialogRef.componentInstance.title = title;
      dialogRef.componentInstance.message = message;
      dialogRef.componentInstance.no = no;
      dialogRef.componentInstance.yes = yes;

      dialogRef.afterClosed().subscribe((value: boolean) => {
        resolve(value);
      });
    });
  }

  confirmSimple(title: string = undefined, message: string = undefined): Promise<void> {
    return new Promise((resolve) => {
      const dialofRef = this.dialog.open(DialogConfirmComponent);

      dialofRef.componentInstance.title = title;
      dialofRef.componentInstance.message = message;

      dialofRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe(() => resolve());
    });
  }

  confirmDeleteUser(title: string, message: string, idUser: number): Promise<void> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(DialogConfirmDeleteUserComponent);
      dialogRef.componentInstance.title = title;
      dialogRef.componentInstance.message = message;
      dialogRef.componentInstance.idUser = idUser;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe(() => resolve());
    });
  }

  confirmDeleteUserSdlv(title: string, message: string, idUser: number): Promise<void> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(DialogConfirmDeleteUserSdlvComponent, {
        panelClass: 'dialog'
      });

      dialogRef.componentInstance.idUser = idUser;
      dialogRef.componentInstance.title = title;
      dialogRef.componentInstance.message = message;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe(() => {
          resolve();
        });
    });
  }

  selectYoungDuplicate(listOfYoung: Array<FormSelectItem>, isEdit = false): Promise<number> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(DialogSelectYoungCreationDuplicateComponent, {
        panelClass: 'dialog'
      });
      dialogRef.componentInstance.isEdit = isEdit;
      dialogRef.componentInstance.duplicates = listOfYoung;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((value) => {
          resolve(value);
        });
    });
  }

  selectYoungFamily(
    families: Array<User>,
    needResp1 = true,
    needResp2 = true
  ): Promise<TuteursYoung> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(DialogSelectYoungCreationFamilyComponent, {
        panelClass: 'dialog-large'
      });

      dialogRef.componentInstance.families = families;
      dialogRef.componentInstance.needResp1 = needResp1;
      dialogRef.componentInstance.needResp2 = needResp2;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((value) => {
          resolve(value);
        });
    });
  }

  selectResponsableDuplicate(listOfResponsable: Array<FormSelectItem>) {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DialogSelectRespLegalComponent, {
        panelClass: 'dialog'
      });

      dialogRef.componentInstance.duplicates = listOfResponsable;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((value) => {
          resolve(value);
        });
    });
  }

  showPopupDoc(idUser, doc) {
    const dialogRef = this.dialog.open(DialogShowDocsComponent, {
      panelClass: 'dialog-large'
    });

    dialogRef.componentInstance.idUser = idUser;
    dialogRef.componentInstance.doc = doc;
  }

  showPopupDocAnnexe(file) {
    const dialogRef = this.dialog.open(DialogShowDocsComponent, {
      panelClass: 'dialog-large'
    });

    dialogRef.componentInstance.fileInfoDto = file;
  }

  showPopupVerifOCR(file) {
    const dialogRef = this.dialog.open(DialogShowDocsComponent, {
      panelClass: 'dialog-large'
    });

    dialogRef.componentInstance.file = file;
  }

  popupAttestation(young: User, isMinor = false) {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(DialogAttestationComponent);
      dialogRef.componentInstance.young = young;
      dialogRef.componentInstance.isMinor = isMinor;
      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((value) => {
          resolve(value);
        });
    });
  }

  errorInscription(title: string = undefined, messages: ErrorInscriptionDto[] = undefined) {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(DialogInscriptionErrorsComponent);

      dialogRef.componentInstance.title = title;
      dialogRef.componentInstance.messages = messages;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((value) => {
          resolve(value);
        });
    });
  }

  popupRGPDContact(hasValidDates = true) {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(DialogCheckRgpdContactComponent);
      dialogRef.componentInstance.hasValidDates = hasValidDates;
      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((value) => {
          resolve(value);
        });
    });
  }
}
