import {User} from './user.model';
import {RoleEnum} from '../enums/role.enum';
import {ProfessionalSituation} from './professional-situation.model';
import {RegisteredEvent} from '../event/registered-event.model';
import {RegisteredProgram} from '../event/registered-program.model';
import {UserDifficulty} from './user-difficulty.model';
import {UserStrength} from './user-strength.model';
import {Place} from '../event/place/place.model';
import {MedicalQuestionnaire} from './medical-questionnaire.model';

export class Year {
  id: number;
  activityHolidays: boolean;
  nameActivityHolidays: string;
  sportPraticated: boolean;
  nameSportPraticated: string;
  interest: string;
  target: string;
  educationalCounselling: string;
  structureSupport: boolean;
  pathMedicalCertificate: string;
  validatedMedicalCertificate: boolean;
  validatedDateMedicalCertificate: Date;
  dateUpdate: Date;
  role: RoleEnum;
  user: User;
  place: Place;

  dateStart: Date;
  dateEnd: Date;

  hasParentalAuthorization: boolean;
  yearsValidationDate: Date;
  idUserAuthorization: number;

  dateAttestation?: Date;
  placeAttestation?: string;
  signAttestation?: boolean;

  medicalQuestionnaireStatus?: boolean;
  medicalQuestionnaireStatusDate?: Date;

  parentalAuthorizationDate: Date;
  acceptedRGPD: boolean;
  acceptedRGPDId: number;
  acceptedRGPDDate: Date;
  contactAccepted: boolean;
  contactAcceptedId: number;
  contactAcceptedDate: Date;

  listOfProfessionalSituation: ProfessionalSituation[];
  listOfRegisteredEvent: RegisteredEvent[];
  listOfRegisteredProgram: RegisteredProgram[];
  listOfUserDifficulty: UserDifficulty[];
  listOfUserStrongPoint: UserStrength[];
  listOfMedicalQuestionnaire: MedicalQuestionnaire[];

  isValidated: boolean;
  idPlace: number;

  idUser: number;

  isValidatedJob?: boolean;
  yearsValidationJobDate?: Date;
  idUserAuthorizationJob?: number;
  hasParentalAuthorizationJob?: boolean;
  parentalAuthorizationDateJob?: Date;
  engagementChart: boolean;

  static convertYears(years: Year) {
    if (years.dateStart) {
      years.dateStart = new Date(years.dateStart);
    }
    if (years.dateEnd) {
      years.dateEnd = new Date(years.dateEnd);
    }
    if (years.validatedDateMedicalCertificate) {
      years.validatedDateMedicalCertificate = new Date(years.validatedDateMedicalCertificate);
    }
    if (years.dateUpdate) {
      years.dateUpdate = new Date(years.dateUpdate);
    }
  }
}
