import {PAccompanimentCategory} from '../param/p-accompaniment-category.model';
import {Jobbeur} from './jobbeur.model';

export class JobbeurAccompaniment {
  idJobbeur: number;
  idPAccompanimentCategory: number;

  jobbeur: Jobbeur;
  pAccompanimentCategory: PAccompanimentCategory;
}
