import {Young} from './young.model';
import {PMeansTransport} from '../param/p-means-transport.model';

export class YoungMeansTransport {
  idYoung: number;
  idPMeansTransport: number;

  young: Young;
  pMeansTransport: PMeansTransport;
}
