import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-radio-btn-renderer',
  templateUrl: './radio-btn-renderer.component.html',
  styleUrls: ['./radio-btn-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, FormsModule, ReactiveFormsModule]
})
export class RadioBtnRendererComponent implements ICellRendererAngularComp {
  rowData;
  value;
  name;
  param;
  formGroup;

  radioClick() {
    this.param.context.componentParent.setRadio(this.name, this.value);
  }

  refresh(params): boolean {
    this.param = params;
    this.name = params.column.colId;
    this.formGroup = this.param.context.componentParent.formGroup;
    this.value = params.value;
    return null;
  }

  agInit(params): void {
    this.param = params;
    this.value = params.value;
    this.name = params.column.colId;
    this.formGroup = this.param.context.componentParent.formGroup;
  }
}
