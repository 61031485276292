import {PInterestField} from '../param/p-interest-field.model';
import {Jobbeur} from './jobbeur.model';

export class JobbeurInterestField {
  idPInterestField: number;
  idJobbeur: number;

  pInterestField: PInterestField;
  jobbeur: Jobbeur;
}
