<div *ngIf="formGroup"
     [formGroup]="formGroup">
  <input (click)="radioClick()"
         [formControlName]="name"
         [id]="rowData"
         [name]="name"
         [value]="value"
         type="radio">
</div>

