import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {FormSelectItem} from '../../../../model/form/select-item.model';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {NgFor, NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-select-young-creation-duplicate',
  templateUrl: './dialog-select-young-creation-duplicate.component.html',
  styleUrls: ['./dialog-select-young-creation-duplicate.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, NgFor, FormsModule, MatButtonModule, NgIf]
})
export class DialogSelectYoungCreationDuplicateComponent implements OnInit, OnDestroy {
  result;
  abort = true;
  duplicates: Array<FormSelectItem> = [];
  isEdit: boolean;

  constructor(
    public readonly dialogRef: MatDialogRef<DialogSelectYoungCreationDuplicateComponent>
  ) {}

  ngOnInit() {
    if (!this.duplicates || this.duplicates.length === 0) {
      this.dialogRef.close(-1);
    }
  }

  createYoung() {
    this.result = 0;
    this.abort = false;
    this.dialogRef.close();
  }

  exit() {
    this.abort = false;
    this.dialogRef.close();
  }

  abortAction() {
    this.abort = true;
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // Exit without choose young or create new
    if (this.abort) {
      this.dialogRef.close(-1);
    } else {
      this.dialogRef.close(this.result);
    }
  }
}
