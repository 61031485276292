export enum HighestLevelEnum {
  NO_BASIC_KNOWLEDGE_DIPLOMA,
  NO_PROFESSIONAL_SKILLS_DIPLOMA,
  DNB,
  CAP_BEP,
  BACCALAUREAT,
  BAC_1,
  BAC_2,
  BAC_3,
  BAC_4,
  BAC_5
}
