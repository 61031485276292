import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {SexeEnum} from 'src/app/model/enums/sexe.enum';
import {FormatService} from 'src/app/services/format.service';

@Component({
  selector: 'app-sex-cell-renderer',
  templateUrl: './sex-cell-renderer.component.html',
  styleUrls: ['./sex-cell-renderer.component.scss'],
  standalone: true
})
export class SexCellRendererComponent implements OnInit, ICellRendererAngularComp {
  youngSex: SexeEnum;
  formatedSex: string;

  constructor(private readonly formatService: FormatService) {}

  refresh(): boolean {
    return false;
  }

  agInit(params): void {
    this.youngSex = params.data.sex;
  }

  ngOnInit() {
    this.formatedSex = this.formatService.formatSex(this.youngSex, true);
  }
}
