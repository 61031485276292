import {Component, OnInit} from '@angular/core';
import {Destroyed} from '../../directives/destroyed.directive';
import {globalAppParam} from '../../../../../environments/environment';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {User} from '../../../../model/user/user.model';
import {RelationUserEnum} from '../../../../model/enums/relation-user.enum';
import {MatButtonModule} from '@angular/material/button';
import {InputTextComponent} from '../../form-parts/form-items/input-text/input-text.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgIf} from '@angular/common';
import {YoungCreationDataService} from "../../../../services/young-creation-data.service";

@Component({
  selector: 'app-dialog-attestation',
  templateUrl: './dialog-attestation.component.html',
  styleUrls: ['./dialog-attestation.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogContent,
    NgIf,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    InputTextComponent,
    MatDialogActions,
    MatButtonModule,
    MatDialogTitle
  ]
})
export class DialogAttestationComponent extends Destroyed implements OnInit {
  isMinor: boolean;
  formGroupAttestation: UntypedFormGroup;
  young: User;

  constructor(
    public dialogRef: MatDialogRef<DialogAttestationComponent>,
    private readonly youngCreationDataService: YoungCreationDataService,
    private readonly fb: UntypedFormBuilder
  ) {
    super();
  }

  get fAttestation() {
    return this.formGroupAttestation.controls;
  }

  get youngName() {
    return this.young.firstName + ' ' + this.young.lastName;
  }

  get legalResponsibleName() {
    if (this.young && this.young.listOfUser1 && this.young.listOfUser1.length > 0) {
      const userRelative = this.young.listOfUser1.filter(
        (item) => item.relationUserEnum === RelationUserEnum.TUTOR1
      )[0].relative;
      if (userRelative) {
        return userRelative.firstName + ' ' + userRelative.lastName;
      }
    }
    return '';
  }

  get currentYear() {
    const currentDate = new Date();
    if (currentDate < globalAppParam.limitDateInscription) {
      if (currentDate.getFullYear() === globalAppParam.limitDateInscription.getFullYear()) {
        return currentDate.getFullYear() - 1 + '-' + currentDate.getFullYear();
      } else {
        return currentDate.getFullYear() + '-' + globalAppParam.limitDateInscription.getFullYear();
      }
    } else {
      return currentDate.getFullYear() + '-' + (currentDate.getFullYear() + 1);
    }
  }

  ngOnInit() {
    this.young.firstName = this.youngCreationDataService.testUserInfoFormGroup.value.firstName;
    this.young.lastName = this.youngCreationDataService.testUserInfoFormGroup.value.lastName;
    this.young.listOfUser1 = this.youngCreationDataService.infoRespLegalFormGroup.value?.families;
    this.formGroupAttestation = this.fb.group({
      dateAttestation: [null, Validators.required],
      placeAttestation: [null, Validators.required],
      signAttestation: [null, Validators.required]
    });
  }

  validAttestation() {
    this.dialogRef.close(this.formGroupAttestation.value);
  }
}
