
  <ng-container *ngIf='hasValidDates else noDates'>
    <h1 mat-dialog-title>Autorisations à renouveler</h1>
    <mat-dialog-content>
      <p>Une ou plusieurs des autorisations doivent être renouvelées :</p>
      <p>- RGPD</p>
      <p>- Autorisation à l'inscription pour Job dans la ville</p>
    </mat-dialog-content>
    <div mat-dialog-actions align="end">
      <button (click)='close()' class='btn btn-primary my-2 mx-2' type='button'>Fermer</button>
    </div>
  </ng-container>
  <ng-template #noDates>
    <h1 mat-dialog-title>Autorisations à remplir</h1>
    <p>Les autorisations ci-dessous doivent être remplies :</p>
    <p>- RGPD</p>
    <p>- Autorisation à l'inscription pour Job dans la ville</p>
    <div class='d-flex flex-row justify-content-end'>
      <button (click)='close()' class='btn btn-primary my-2 mx-2' type='button'>Fermer</button>
    </div>
  </ng-template>
