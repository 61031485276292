<div class="d-flex flex-row align-items-baseline" mat-dialog-title>
  <h4>
    {{ families.length }} responsable{{ families.length > 1 ? 's' : '' }}
    lég{{ families.length > 1 ? 'aux' : 'al' }} {{ families.length > 1 ? 'peuvent' : 'peut' }}
    être associé{{ families.length > 1 ? 's' : '' }} à ce jeune. Sélectionnez les responsables s'ils sont
    correspondants.
  </h4>
  <span class="flex-fill separator"></span>
  <button (click)="abortAction()"
          aria-label="Close"
          class="close"
          type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div mat-dialog-content>
  <app-grid-family (radioChange)="onRadioChange($event)"
                   [family]="families"
                   [needResp1]="needResp1"
                   [needResp2]="needResp2"
                   class="d-flex flex-fill grid-family">
  </app-grid-family>
</div>
<div align="end" mat-dialog-actions class="btn-container gap-20">
  <button (click)="exit(true)" class="btn btn-outline-primary">Passer</button>
  <button (click)="exit()" class="btn btn-primary">Valider</button>
</div>


