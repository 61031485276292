import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {FormatService} from 'src/app/services/format.service';
import {SexCellRendererComponent} from '../custom-cell/sex-cell-renderer/sex-cell-renderer.component';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {User} from '../../../../model/user/user.model';
import {RadioBtnRendererComponent} from '../custom-cell/radio-btn-renderer/radio-btn-renderer.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AsyncPipe} from '@angular/common';
import {AgGridModule} from '@ag-grid-community/angular';
import {ConnectedStatusEnum} from '../../../../model/enums/connected-status.enum';

@Component({
  selector: 'app-grid-family',
  templateUrl: './grid-family.component.html',
  styleUrls: ['./grid-family.component.scss'],
  standalone: true,
  imports: [AgGridModule, AsyncPipe]
})
export class GridFamilyComponent implements OnInit, OnChanges {
  @Input() family: User[];
  @Input() needResp1 = true;
  @Input() needResp2 = true;
  @Output() radioChange = new EventEmitter();
  formGroup: UntypedFormGroup;
  rowClassRules;
  gridYoungApi: unknown;
  columnYoungDefs;
  gridYoungOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = '';
  paginationSize = 10;
  langue = agGridLanguageFr;
  private radiofamily1;
  private radiofamily2;

  constructor(
    private readonly formatService: FormatService,
    private readonly fb: UntypedFormBuilder
  ) {
    this.context = {componentParent: this};
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      isTutor1: undefined,
      isTutor2: undefined
    });

    this.columnYoungDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        sortable: true,
        minWidth: 25,
        maxWidth: 300
      },
      {
        headerName: 'Sexe',
        cellRenderer: SexCellRendererComponent,
        field: 'sex',
        sortable: true,
        minWidth: 25,
        maxWidth: 100
      },
      {
        headerName: 'Adresse',
        field: 'address',
        sortable: true,
        minWidth: 25
      },
      {
        headerName: 'Statut',
        field: 'isConnected',
        sortable: true,
        minWidth: 25,
        maxWidth: 100
      }
    ];
    if (this.needResp1) {
      const resp1Def = {
        headerName: 'Principal',
        field: 'isTutor1',
        cellRenderer: RadioBtnRendererComponent,
        sortable: true,
        minWidth: 25,
        maxWidth: 100
      };
      this.columnYoungDefs.push(resp1Def);
    }
    if (this.needResp2) {
      const resp2Def = {
        headerName: 'Secondaire',
        field: 'isTutor2',
        cellRenderer: RadioBtnRendererComponent,
        sortable: true,
        minWidth: 25,
        maxWidth: 100
      };
      this.columnYoungDefs.push(resp2Def);
    }

    this.gridYoungOption = {
      defaultColDef: {
        flex: 1,
        width: 100,
        resizable: true,
        filter: false,
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      rowHeight: 60
    } as GridOptions;
  }

  setRadio(name, value) {
    if (name === 'isTutor1') {
      if (this.radiofamily1 === value) {
        this.radiofamily1 = undefined;
      } else {
        this.radiofamily1 = value;
      }
      if (this.radiofamily2 === this.radiofamily1) {
        this.radiofamily2 = undefined;
      }
    } else if (name === 'isTutor2') {
      if (this.radiofamily2 === value) {
        this.radiofamily2 = undefined;
      } else {
        this.radiofamily2 = value;
      }
      if (this.radiofamily1 === this.radiofamily2) {
        this.radiofamily1 = undefined;
      }
    }
    this.radioChange.emit({
      family1: this.radiofamily1,
      family2: this.radiofamily2
    });
    this.formGroup.patchValue(
      {
        isTutor1: this.radiofamily1,
        isTutor2: this.radiofamily2
      },
      {
        emitEvent: false
      }
    );
  }

  onGridReady(params) {
    this.gridYoungOption.api.sizeColumnsToFit();
    this.gridYoungOption.animateRows = true;
    this.gridYoungApi = params.api;
    this.gridYoungOption.suppressCellFocus = true;
    this.gridYoungOption.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    if (this.gridYoungOption && this.gridYoungOption.api && this.family) {
      this.gridYoungOption.api.setRowData(this.family.map((value) => this.getRow(value)));
    }
  }

  getRow(user: User) {
    return {
      id: user.id,
      name: this.formatService.formatName(user),
      firstName: user.firstName,
      lastName: user.lastName,
      sex: user.sex,
      phone: user.phone,
      email: user.email,
      address: this.formatService.formatAddress(user.address),
      isConnected: ConnectedStatusEnum.format(user.isConnected),
      isTutor1: user.id,
      isTutor2: user.id
    };
  }

  onGridSizeChanged(params) {
    this.gridYoungOption.api.sizeColumnsToFit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setAffichage();
  }
}
