<div class="dialog-default d-flex flex-column">
  <h1 *ngIf="title" mat-dialog-title>{{title}}</h1>
  <p *ngIf="message">{{message}}</p>
  <p> • Suppression intégrale de l'utilisateur</p>
  <p> • Suppression anonymisée qui ne supprime que les informations sur les données personnelles de l'utilisateur</p>
  <div class="d-flex flex-row justify-content-end">
    <button (click)="suppressionTotale()" class="btn btn-danger my-2 mx-2" type="button">Supprimer intégralement
    </button>
    <button (click)="suppressionAnonymisee()" class="btn btn-primary my-2 mx-2" type="button">Suppression anonymisée
    </button>
    <button (click)="Fermer()" class="btn btn-warning my-2 mx-2" type="button">Fermer</button>
  </div>
</div>
