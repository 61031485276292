import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {FormSelectItem} from '../../../../model/form/select-item.model';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {NgFor} from '@angular/common';

@Component({
  selector: 'app-dialog-select-resp-legal',
  templateUrl: './dialog-select-resp-legal.component.html',
  styleUrls: ['./dialog-select-resp-legal.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, NgFor, FormsModule, MatButtonModule]
})
export class DialogSelectRespLegalComponent implements OnInit, OnDestroy {
  result;
  abort = true;
  duplicates: Array<FormSelectItem> = [];

  constructor(public dialogRef: MatDialogRef<DialogSelectRespLegalComponent>) {}

  ngOnInit() {
    if (!this.duplicates || this.duplicates.length === 0) {
      this.dialogRef.close(-1);
    }
  }

  exit() {
    this.abort = false;
    this.dialogRef.close();
  }

  abortAction() {
    this.abort = true;
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // Exit without choose young or create new
    if (this.abort) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.result);
    }
  }
}
