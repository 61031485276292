import {User} from '../../model/user/user.model';
import {ConnectedStatusEnum} from '../../model/enums/connected-status.enum';
import {PhoneRegionCodeEnum} from '../../model/enums/phone-region-code.enum';
import {Young} from '../../model/user/young.model';
import {UntypedFormGroup} from '@angular/forms';
import {isNotNullOrUndefined, trim} from '../utils.static';
import {SchoolLevel} from '../../model/user/school-level.model';
import {RelationUserForm} from '../../model/creation/relation-user.form';
import {RelationUser} from '../../model/user/relation-user.model';
import {RelationUserEnum} from '../../model/enums/relation-user.enum';
import {Address} from '../../model/event/place/address.model';
import {Year} from '../../model/user/year.model';
import {SdlvTabForm} from '../../model/creation/sdlv-tab.form';
import {ProgramEnum} from '../../model/enums/program.enum';
import {RegisteredProgram} from '../../model/event/registered-program.model';
import {SimpleEventListDTO} from '../../model/dto/simple-event-list.dto';
import {RegisteredEvent} from '../../model/event/registered-event.model';
import {getLatestImageRight, YoungImageRight} from '../../model/user/young-image-right.model';
import {BehaviorSubject} from 'rxjs';
import {PMonitoringStatusSource} from '../../model/enums/source/p-monitoring-status.source';
import {Monitoring} from '../../model/monitoring/monitoring.model';
import {JobTabForm} from '../../model/creation/job-tab.form';
import {Parcours} from '../../model/event/parcours/parcours.model';
import {Booster} from '../../model/event/parcours/booster.model';
import {YoungMobility} from '../../model/user/young-mobility.model';
import {YoungMeansTransport} from '../../model/user/young-means-transport.model';
import {JobbeurAccompaniment} from '../../model/user/jobbeur-accompaniment.model';
import {PillarJobbeur} from '../../model/user/jobbeur-pillar.model';
import {JobbeurInterestField} from '../../model/user/jobbeur-interest-field.model';
import {JobbeurTravel} from '../../model/user/jobbeur-travel.model';
import {JobbeurProject} from '../../model/user/jobbeur-project.model';
import {JobbeurPDomaine} from '../../model/user/jobbeur-need-p-domaine.model';
import {JobbeurFinancingMechanism} from '../../model/user/jobbeur-financing-mechanism.model';
import {Jobbeur} from '../../model/user/jobbeur.model';
import {HighestLevelEnum} from '../../model/enums/highest-level.enum';

export class YoungCreationInitHelper {
  static setJdlvInformations(
    user: User,
    programList: ProgramEnum[],
    jobInfoYoungFormGroup: UntypedFormGroup,
    jobInfoJobFormGroup: UntypedFormGroup,
    jobInfoBesoinFormGroup: UntypedFormGroup,
    jobTabs: JobTabForm
  ) {
    if (programList.find((item) => item === ProgramEnum.JOBDANSLAVILLE)) {
      if (
        jobInfoYoungFormGroup.valid &&
        jobInfoJobFormGroup.valid &&
        jobInfoBesoinFormGroup.valid &&
        (!jobTabs.hasJobbeurNeed || (jobTabs.hasJobbeurNeed && jobTabs.jobbeurNeed.length > 0))
      ) {
        this.setJobInfo(user, jobTabs);
        this.setIsValidatedJob(user);
      }
    } else {
      if (
        user.young?.listOfMonitoring?.length > 0 &&
        user.young.listOfMonitoring.find((item) => !item.dateEnd).idPMonitoringStatus !==
          PMonitoringStatusSource.OUT
      ) {
        user.young.listOfMonitoring.find((item) => !item.dateEnd).dateEnd = new Date();
        const monitoring = new Monitoring();
        monitoring.dateEnd = new Date();
        monitoring.idPMonitoringStatus = PMonitoringStatusSource.OUT;

        user.young.listOfMonitoring.push(monitoring);
      }
    }
  }

  static setSdlvInformations(
    currentUser: User,
    programList: BehaviorSubject<unknown[]>,
    sdlvInfoFormGroup: UntypedFormGroup,
    sdlvTabs: SdlvTabForm,
    registerEventSub: Map<number, Event[]>,
    registerEventUnsub: Map<number, Event[]>
  ) {
    if (
      programList.getValue().find((item) => item === ProgramEnum.SPORTDANSLAVILLE) &&
      sdlvInfoFormGroup.valid
    ) {
      YoungCreationInitHelper.setYoungAndYearSDLV(
        currentUser,
        sdlvInfoFormGroup,
        sdlvTabs,
        registerEventSub,
        registerEventUnsub
      );
    }
  }

  static setYear(currentUser: User, dataCurrentYear: Year, placeFormGroup: UntypedFormGroup) {
    const currentYear: Year = dataCurrentYear;
    const placeForm = placeFormGroup.value;

    currentYear.idPlace = placeForm.place;

    if (currentUser && !currentUser.listOfYears) {
      currentUser.listOfYears = [];
    }
    if (currentYear.id) {
      const index = currentUser.listOfYears.findIndex((year) => currentYear.id === year.id);
      if (index >= 0) {
        currentUser.listOfYears[index] = currentYear;
      } else {
        currentUser.listOfYears[0] = currentYear;
      }
    } else {
      currentUser.listOfYears[0] = currentYear;
    }
  }

  static setFamily(
    currentUser: User,
    relationUserForm: RelationUserForm[],
    isRespSameAddress: boolean
  ) {
    const familiesForm: RelationUserForm[] = relationUserForm;

    let resp1: RelationUserForm;
    let resp2: RelationUserForm;
    if (familiesForm.length > 0) {
      resp1 = familiesForm.find(
        (value: RelationUser) => value?.relationUserEnum === RelationUserEnum.TUTOR1
      );
      resp2 = familiesForm.find(
        (value: RelationUser) => value?.relationUserEnum === RelationUserEnum.TUTOR2
      );
    }

    if (!resp1 && resp2) {
      resp1 = JSON.parse(JSON.stringify(resp2));
      resp2 = null;
    }

    if (isRespSameAddress && resp2) {
      resp2.relative.address = resp1.relative.address;
    }

    const family = [];

    currentUser.listOfUser1 = [];

    this.initResp(resp1, resp2, family, currentUser);

    for (let i = 0; i < familiesForm.length; i++) {
      this.setRelationUserAndRelative(family, i, currentUser, familiesForm);
    }
    if (currentUser.listOfUser1?.length > 0 && currentUser.listOfUser1[0]?.relative?.phone) {
      currentUser.young.phone = this.formatPhone('+33', currentUser.listOfUser1[0].relative.phone);
    } else {
      currentUser.young.phone = null;
    }
  }

  static setUser(
    testUserInfoFormGroup: UntypedFormGroup,
    infoUserGeneralFormGroup: UntypedFormGroup,
    schoolLevels: SchoolLevel[],
    dataCurrentUser: User
  ): User {
    const testUserForm = testUserInfoFormGroup.value;
    const userForm = infoUserGeneralFormGroup.value;
    const currentUser: User = dataCurrentUser;

    //set User
    currentUser.firstName = trim(testUserForm.firstName);
    currentUser.lastName = trim(testUserForm.lastName);
    currentUser.birthday = this.adjustDateForTimeOffset(testUserForm.birthday);
    currentUser.sex = userForm.sex;
    currentUser.email = trim(userForm.email);
    currentUser.address = userForm.address;
    currentUser.isConnected = ConnectedStatusEnum.VALIDATED;
    if (userForm.phone) {
      currentUser.phone = this.formatPhone(PhoneRegionCodeEnum[userForm.phoneCode], userForm.phone);
    } else {
      currentUser.phone = null;
    }
    return this.setYoung(currentUser, infoUserGeneralFormGroup, schoolLevels);
  }

  private static adjustDateForTimeOffset(dateToAdjust: Date) {
    const offsetMs = new Date(dateToAdjust).getTimezoneOffset() * 60000;
    return new Date(new Date(dateToAdjust).getTime() - offsetMs);
  }

  private static formatPhone(code: string, num: string) {
    if (num && num.length === 10) {
      return code && code !== '' ? code + num.slice(1) : num;
    } else if (num && num.length === 9) {
      return code && code !== '' ? code + num : `+33${num}`;
    } else {
      return undefined;
    }
  }

  private static setYoung(
    currentUser: User,
    infoYoungGeneralFormGroup: UntypedFormGroup,
    schoolLevels: SchoolLevel[]
  ): User {
    const youngForm: Young = infoYoungGeneralFormGroup.value;

    youngForm.listOfSchoolLevel = schoolLevels;
    // Attention si créé ou pas
    if (!currentUser.young.listOfSchoolLevel) {
      currentUser.young.listOfSchoolLevel = [];
    }
    if (youngForm.listOfSchoolLevel && youngForm.listOfSchoolLevel.length > 0) {
      currentUser.young.listOfSchoolLevel = youngForm.listOfSchoolLevel.map((value) => {
        value.pSchoolLevel = null;
        return value;
      });
    }

    // set Young

    currentUser.young.numberBrother = youngForm.numberBrother;
    currentUser.young.numberSister = youngForm.numberSister;
    currentUser.young.handicap = youngForm.handicap;
    currentUser.young.medicalObservation = youngForm.medicalObservation;
    if (youngForm.childs) {
      currentUser.young.childs = youngForm.childs;
    } else {
      currentUser.young.childs = 0;
    }
    currentUser.young.drivingLicence = !!youngForm.drivingLicence;
    return currentUser;
  }

  private static setYoungSDLV(
    currentUser: User,
    sdlvInfoFormGroup: UntypedFormGroup,
    sdlvTabs: SdlvTabForm
  ): User {
    const sdlvForm = sdlvInfoFormGroup.value;
    currentUser.young.height = sdlvForm.height;
    currentUser.young.weight = sdlvForm.weight;
    if (!sdlvForm.comeAlone && User.formatAge(currentUser.birthday) >= 14) {
      currentUser.young.comeAlone = true;
    } else {
      currentUser.young.comeAlone = sdlvForm.comeAlone;
    }

    if (currentUser.young.youngImageRights?.length > 0) {
      const latestImageRight = getLatestImageRight(currentUser.young.youngImageRights);
      if (latestImageRight.hasImageRight !== sdlvTabs.imageRightAutorization) {
        const youngImageRight = new YoungImageRight();
        youngImageRight.hasImageRight = sdlvTabs.imageRightAutorization;
        youngImageRight.imageRightAutorizationDate = sdlvTabs.imageRightAutorizationDate;
        currentUser.young.youngImageRights.push(youngImageRight);
      }
    } else {
      currentUser.young.youngImageRights = [];
      const youngImageRight = new YoungImageRight();
      youngImageRight.hasImageRight = sdlvTabs.imageRightAutorization;
      youngImageRight.imageRightAutorizationDate = sdlvTabs.imageRightAutorizationDate;
      currentUser.young.youngImageRights.push(youngImageRight);
    }

    return currentUser;
  }

  private static setJobInfo(currentUser: User, jobTabs: JobTabForm) {
    let registerProgram: RegisteredProgram;
    //Si un registerProgram sur job existe déjà
    if (
      currentUser.listOfYears[0]?.listOfRegisteredProgram?.some(
        (item) => item.idProgram === ProgramEnum.JOBDANSLAVILLE
      )
    ) {
      registerProgram = currentUser.listOfYears[0].listOfRegisteredProgram.find(
        (item) => item.idProgram === ProgramEnum.JOBDANSLAVILLE
      );
      this.updateExistingRegisterProgram(registerProgram, jobTabs);
    } else {
      registerProgram = new RegisteredProgram();
      registerProgram.idProgram = ProgramEnum.JOBDANSLAVILLE;
      registerProgram.idYears = currentUser.listOfYears[0].id;
      registerProgram.listOfParcours = [];

      this.createFirstMonitoring(currentUser);

      this.addParcoursAndBooster(registerProgram, jobTabs);

      if (!currentUser.listOfYears[0].listOfRegisteredProgram) {
        currentUser.listOfYears[0].listOfRegisteredProgram = [];
      }
      currentUser.listOfYears[0].listOfRegisteredProgram.push(registerProgram);
    }

    // Infos Jeune
    this.initYoungInfo(currentUser, jobTabs);

    if (jobTabs.idRi) {
      const relationUserExists = currentUser.listOfUser1.find(
        (item) => item.relationUserEnum === RelationUserEnum.RI && !item.dateEnd
      );

      if (relationUserExists && jobTabs.idRi !== relationUserExists.idRelative) {
        relationUserExists.idRelative = jobTabs.idRi;
      } else if (!relationUserExists) {
        const relationUser = new RelationUser();
        relationUser.idRelative = jobTabs.idRi;
        relationUser.relationUserEnum = RelationUserEnum.RI;
        relationUser.idUser = currentUser.id;
        relationUser.dateBegin = new Date();
        relationUser.dateUpdate = new Date();
        currentUser.listOfUser1.push(relationUser);
      }
    }

    this.initSubYoungInfo(currentUser, jobTabs);

    // Infos Besoins
    this.initJobbeurInfo(currentUser, jobTabs);

    this.initSubJobbeurInfo(currentUser, jobTabs);
  }

  private static initSubYoungInfo(currentUser: User, jobTabs: JobTabForm) {
    if (jobTabs.mobility && jobTabs.mobility.length > 0) {
      jobTabs.mobility.forEach((value) => {
        const youngMobility = new YoungMobility();
        youngMobility.idPMobility = value;
        youngMobility.idYoung = currentUser.id;

        currentUser.young.listOfYoungMobility.push(youngMobility);
      });
    }

    if (jobTabs.meansTransport && jobTabs.meansTransport.length > 0) {
      jobTabs.meansTransport.forEach((value) => {
        const youngMeansTransport = new YoungMeansTransport();
        youngMeansTransport.idPMeansTransport = value;
        youngMeansTransport.idYoung = currentUser.id;

        currentUser.young.listOfYoungMeansTransport.push(youngMeansTransport);
      });
    }

    if (jobTabs.socialMedias && jobTabs.socialMedias.length > 0) {
      jobTabs.socialMedias.forEach((value) => {
        if (value.idPSocialMedia && value.identifiant) {
          value.idYoung = currentUser.id;
          currentUser.young.listOfSocialMedia.push(value);
        }
      });
    }
  }

  private static initSubJobbeurInfo(currentUser: User, jobTabs: JobTabForm) {
    if (jobTabs.jobbeurAccompaniment?.length > 0) {
      jobTabs.jobbeurAccompaniment.forEach((value) => {
        const jobbeurAccompaniment = new JobbeurAccompaniment();
        jobbeurAccompaniment.idPAccompanimentCategory = value;
        jobbeurAccompaniment.idJobbeur = currentUser.id;
        currentUser.young.jobbeur.listOfJobbeurAccompaniment.push(jobbeurAccompaniment);
      });
    }

    if (jobTabs.jobbeurNeed?.length > 0) {
      jobTabs.jobbeurNeed.forEach((value) => {
        if (
          isNotNullOrUndefined(value.idPNeed) &&
          value.expectedDeadline &&
          isNotNullOrUndefined(value.duration)
        ) {
          value.idJobbeur = currentUser.id;
          currentUser.young.jobbeur.listOfJobbeurNeed.push(value);
        }
      });
    }

    if (jobTabs.pillars?.length > 0) {
      jobTabs.pillars.forEach((value) => {
        const pillarJobbeur = new PillarJobbeur();
        pillarJobbeur.idPPillar = value;
        pillarJobbeur.idJobbeur = currentUser.id;
        currentUser.young.jobbeur.listOfPillarJobbeur.push(pillarJobbeur);
      });
    }

    if (jobTabs.interestFields?.length > 0) {
      jobTabs.interestFields.forEach((value) => {
        const jobbeurInterestField = new JobbeurInterestField();
        jobbeurInterestField.idPInterestField = value.id;
        jobbeurInterestField.idJobbeur = currentUser.id;
        currentUser.young.jobbeur.listOfJobbeurInterestField.push(jobbeurInterestField);
      });
    }

    if (jobTabs.jobbeurTravel?.length > 0) {
      jobTabs.jobbeurTravel.forEach((value) => {
        const jobbeurTravel = new JobbeurTravel();
        jobbeurTravel.idPTravel = value;
        jobbeurTravel.idJobbeur = currentUser.id;
        currentUser.young.jobbeur.listOfJobbeurTravel.push(jobbeurTravel);
      });
    }

    if (jobTabs.jobbeurProject?.length > 0) {
      jobTabs.jobbeurProject.forEach((value) => {
        const jobbeurProject = new JobbeurProject();
        jobbeurProject.idPProject = value;
        jobbeurProject.idJobbeur = currentUser.id;
        currentUser.young.jobbeur.listOfJobbeurProject.push(jobbeurProject);
      });
    }

    if (jobTabs.domainMetiers?.length > 0) {
      jobTabs.domainMetiers.forEach((value) => {
        const jobbeurDomainMetier = new JobbeurPDomaine();
        jobbeurDomainMetier.idPDomaineMetier = value.id;
        jobbeurDomainMetier.idJobbeur = currentUser.id;
        currentUser.young.jobbeur.listOfJobbeurDomainMetier.push(jobbeurDomainMetier);
      });
    }

    if (jobTabs.jobbeurFinancingMechanism?.length > 0) {
      jobTabs.jobbeurFinancingMechanism.forEach((value) => {
        const jobbeurFinancingMechanism = new JobbeurFinancingMechanism();
        jobbeurFinancingMechanism.idPFinancingMechanism = value;
        jobbeurFinancingMechanism.idJobbeur = currentUser.id;
        currentUser.young.jobbeur.listOfJobbeurFinancingMechanisms.push(jobbeurFinancingMechanism);
      });
    }
  }

  private static initJobbeurInfo(currentUser: User, jobTabs: JobTabForm) {
    currentUser.young.jobbeur = new Jobbeur();
    currentUser.young.jobbeur.id = currentUser.id;
    currentUser.young.jobbeur.hasJobbeurNeed = isNotNullOrUndefined(jobTabs.hasJobbeurNeed)
      ? jobTabs.hasJobbeurNeed
      : false;
    currentUser.young.jobbeur.listOfJobbeurAccompaniment = [];
    currentUser.young.jobbeur.listOfJobbeurNeed = [];
    currentUser.young.jobbeur.listOfPillarJobbeur = [];
    currentUser.young.jobbeur.listOfJobbeurInterestField = [];
    currentUser.young.jobbeur.listOfJobbeurTravel = [];
    currentUser.young.jobbeur.listOfJobbeurProject = [];
    currentUser.young.jobbeur.listOfJobbeurDomainMetier = [];
    currentUser.young.jobbeur.listOfJobbeurFinancingMechanisms = [];

    currentUser.young.jobbeur.expectedProfessionnalProject = jobTabs.professionalProject;
    currentUser.young.jobbeur.highestDiploma = jobTabs.highestLevel;

    if (jobTabs.highestLevel > HighestLevelEnum.NO_PROFESSIONAL_SKILLS_DIPLOMA) {
      currentUser.young.jobbeur.highestDiplomaDate = new Date(jobTabs.highestLevelDate);
    } else {
      currentUser.young.jobbeur.highestDiplomaDate = null;
    }

    currentUser.young.jobbeur.scolarshipStatus = jobTabs.scolarshipStatus;
    if (jobTabs.endSchoolDate) {
      currentUser.young.jobbeur.endSchoolDate = new Date(jobTabs.endSchoolDate);
    }

    currentUser.young.jobbeur.sponsorWish = isNotNullOrUndefined(jobTabs.sponsorWish)
      ? jobTabs.sponsorWish
      : false;
    if (isNotNullOrUndefined(jobTabs.sponsorWish) || jobTabs.sponsorWish) {
      currentUser.young.jobbeur.sponsorSex = jobTabs.sponsorSex;
    } else {
      currentUser.young.jobbeur.sponsorSex = null;
    }
  }

  private static setIsValidatedJob(user: User) {
    if (!user.listOfYears[0].isValidatedJob) {
      user.listOfYears[0].isValidatedJob = false;
    }
  }

  private static initYoungInfo(currentUser: User, jobTabs: JobTabForm) {
    currentUser.young.idPOrigin = jobTabs.idOrigin;
    currentUser.young.drivingLicence =
      jobTabs.hasDrivingLicence === undefined ? false : jobTabs.hasDrivingLicence;
    currentUser.young.drivingLicenceGettingDate = jobTabs.drivingLicenceDate;
    currentUser.young.listOfYoungMeansTransport = [];
    currentUser.young.listOfYoungMobility = [];
    currentUser.young.listOfSocialMedia = [];

    if (isNotNullOrUndefined(jobTabs.registeredPoleEmploi)) {
      currentUser.young.isInscritPoleEmploi = jobTabs.registeredPoleEmploi;
      currentUser.young.dateEntryPoleEmploi = new Date(jobTabs.registeredPoleEmploiDate);
    }
    if (isNotNullOrUndefined(jobTabs.registeredMissionLocale)) {
      currentUser.young.isInscritMissionLocale = jobTabs.registeredMissionLocale;
      currentUser.young.dateEntryMissionLocal = new Date(jobTabs.registeredMissionLocaleDate);
    }
    if (isNotNullOrUndefined(jobTabs.registeredCapEmploi)) {
      currentUser.young.isInscritCapEmploi = jobTabs.registeredCapEmploi;
      currentUser.young.dateEntryCapEmploi = new Date(jobTabs.registeredCapEmploiDate);
    }

    if (isNotNullOrUndefined(jobTabs.isInternationalProtection)) {
      currentUser.young.isInternationalProtected = jobTabs.isInternationalProtection;
    }

    if (isNotNullOrUndefined(jobTabs.hasIsolatedParent)) {
      currentUser.young.hasParentIsolated = jobTabs.hasIsolatedParent;
    }
  }

  private static addParcoursAndBooster(registerProgram: RegisteredProgram, jobTabs: JobTabForm) {
    if (jobTabs.subProgram) {
      const parcours: Parcours = new Parcours();
      parcours.idPParcours = jobTabs.subProgram;
      if (registerProgram.listOfParcours.length > 1) {
        parcours.dateBegin = new Date();
      } else {
        parcours.dateBegin = jobTabs.subProgramDate;
      }

      const booster: Booster = new Booster();
      booster.isActif = !!jobTabs.booster;
      booster.dateBegin = new Date();
      parcours.listOfBooster = [];
      parcours.listOfBooster.push(booster);

      registerProgram.listOfParcours.push(parcours);
    }
  }

  private static createFirstMonitoring(currentUser: User) {
    if (!currentUser.young.listOfMonitoring || currentUser.young.listOfMonitoring.length === 0) {
      currentUser.young.listOfMonitoring = [];
      const monitoring: Monitoring = new Monitoring();
      monitoring.idYoung = currentUser.id;
      monitoring.dateMonitoringChange = new Date();
      monitoring.idPMonitoringStatus = PMonitoringStatusSource.ACTIVE;
      currentUser.young.listOfMonitoring.push(monitoring);
    }
  }

  private static addNewParcours(registerProgram: RegisteredProgram, jobTabs: JobTabForm) {
    // AJOUT D'UN NOUVEAU PARCOURS
    if (!registerProgram.listOfParcours) {
      registerProgram.listOfParcours = [];
    }

    if (registerProgram.listOfParcours.length > 0) {
      registerProgram.listOfParcours.find((item) => !item.dateEnd).dateEnd = new Date();
    }

    const parcours: Parcours = new Parcours();
    parcours.idPParcours = jobTabs.subProgram;
    if (registerProgram.listOfParcours.length > 0) {
      parcours.dateBegin = new Date();
    } else {
      parcours.dateBegin = jobTabs.subProgramDate;
    }
    parcours.listOfBooster = [];

    const booster: Booster = new Booster();
    booster.isActif = !!jobTabs.booster;
    booster.dateBegin = new Date();
    parcours.listOfBooster.push(booster);
    registerProgram.listOfParcours.push(parcours);
  }

  private static handleBoosterOnParcours(registerProgram: RegisteredProgram, jobTabs: JobTabForm) {
    const parcours: Parcours = registerProgram.listOfParcours.find((item) => !item.dateEnd);

    if (!parcours.listOfBooster) {
      this.addBooster(parcours, jobTabs);
    } else {
      this.checkIfBoosterHasChanged(parcours, jobTabs);
    }
  }

  private static addBooster(parcours: Parcours, jobTabs: JobTabForm) {
    // SI AUCUN BOOSTER
    parcours.listOfBooster = [];
    const booster: Booster = new Booster();
    booster.isActif = !!jobTabs.booster;
    booster.dateBegin = new Date();
    parcours.listOfBooster.push(booster);
  }

  private static checkIfBoosterHasChanged(parcours: Parcours, jobTabs: JobTabForm) {
    if (
      parcours.listOfBooster.length > 0 &&
      parcours.listOfBooster.find((item) => !item.dateEnd).isActif !== jobTabs.booster
    ) {
      //SI LA VALEUR DU BOOSTER A CHANGE
      parcours.listOfBooster.find((item) => !item.dateEnd).dateEnd = new Date();

      const booster: Booster = new Booster();
      booster.isActif = isNotNullOrUndefined(jobTabs.booster) ? jobTabs.booster : false;
      booster.dateBegin = new Date();
      parcours.listOfBooster.push(booster);
    }
  }

  private static updateExistingRegisterProgram(
    registerProgram: RegisteredProgram,
    jobTabs: JobTabForm
  ) {
    if (isNotNullOrUndefined(jobTabs.subProgram)) {
      if (
        registerProgram.listOfParcours?.length > 0 &&
        registerProgram.listOfParcours.find((item) => !item.dateEnd).idPParcours ===
          jobTabs.subProgram
      ) {
        // CHANGEMENT DE BOOSTER SUR UN PARCOURS
        this.handleBoosterOnParcours(registerProgram, jobTabs);
      } else {
        this.addNewParcours(registerProgram, jobTabs);
      }
    }
  }

  private static setYoungAndYearSDLV(
    currentUser: User,
    sdlvInfoFormGroup: UntypedFormGroup,
    sdlvTabs: SdlvTabForm,
    registerEventSub: Map<number, Event[]>,
    registerEventUnsub: Map<number, Event[]>
  ) {
    this.setYearForSDLV(
      currentUser,
      sdlvInfoFormGroup,
      sdlvTabs,
      registerEventSub,
      registerEventUnsub
    );
    this.setYoungSDLV(currentUser, sdlvInfoFormGroup, sdlvTabs);
  }

  private static setYearForSDLV(
    currentUser: User,
    sdlvInfoFormGroup: UntypedFormGroup,
    sdlvTabs: SdlvTabForm,
    registerEventSub: Map<number, Event[]>,
    registerEventUnsub: Map<number, Event[]>
  ) {
    const yearsForm: Year = sdlvInfoFormGroup.value.years;

    currentUser.listOfYears[0].sportPraticated = yearsForm.sportPraticated;
    currentUser.listOfYears[0].nameSportPraticated = trim(yearsForm.nameSportPraticated);
    currentUser.listOfYears[0].activityHolidays = yearsForm.activityHolidays;
    currentUser.listOfYears[0].nameActivityHolidays = trim(yearsForm.nameActivityHolidays);
    // set Year
    currentUser.listOfYears[0].validatedDateMedicalCertificate =
      sdlvTabs.validatedDateMedicalCertificate; // DATE
    currentUser.listOfYears[0].validatedMedicalCertificate = sdlvTabs.validatedMedicalCertificate; // BOOLEAN
    currentUser.listOfYears[0].hasParentalAuthorization = sdlvTabs.hasParentalAuthorization;
    currentUser.listOfYears[0].parentalAuthorizationDate = sdlvTabs.parentalAuthorizationDate;
    currentUser.listOfYears[0].acceptedRGPD = sdlvTabs.acceptedRGPD;
    currentUser.listOfYears[0].acceptedRGPDDate = sdlvTabs.acceptedRGPDDate;
    currentUser.listOfYears[0].contactAccepted = sdlvTabs.contactAccepted;
    currentUser.listOfYears[0].contactAcceptedDate = sdlvTabs.contactAcceptedDate;

    if (!currentUser.listOfYears[0].validatedMedicalCertificate) {
      delete currentUser.listOfYears[0].validatedMedicalCertificate;
    }

    currentUser.listOfYears[0] = this.setProgramStatus(
      this.setRegisterEventOnYear(currentUser.listOfYears[0], registerEventSub, registerEventUnsub)
    );
  }

  private static setRegisterEventOnYear(
    year: Year,
    registerEventSub: Map<number, Event[]>,
    registerEventUnsub: Map<number, Event[]>
  ): Year {
    const eventToSub: SimpleEventListDTO[] = this.flattenMap(registerEventSub);
    const eventToUnsub: SimpleEventListDTO[] = this.flattenMap(registerEventUnsub);

    if (!year.listOfRegisteredEvent || year.listOfRegisteredEvent.length === 0) {
      year.listOfRegisteredEvent = eventToSub
        ? eventToSub.map((event) => this.mapEventToRegisterEvent(event, year.id))
        : [];
      this.desinscriptionEvent(eventToUnsub, year);
    } else {
      if (eventToSub?.length > 0) {
        eventToSub.forEach((event: SimpleEventListDTO) => {
          if (
            !year.listOfRegisteredEvent.find(
              (item) => item.idEvent === event.id && item.idYears === year.id && !item.dateEnd
            )
          ) {
            year.listOfRegisteredEvent.push(this.mapEventToRegisterEvent(event, year.id));
          }
        });
      }
      this.desinscriptionEvent(eventToUnsub, year);
    }

    if (year.listOfRegisteredEvent.length === 0) {
      throw new Error();
    }

    return year;
  }

  private static setProgramStatus(currentYear: Year): Year {
    if (!currentYear.listOfRegisteredProgram) {
      currentYear.listOfRegisteredProgram = [];
    }
    const currentSdlvRegisteredProg = currentYear.listOfRegisteredProgram.find(
      (prog) => prog.idProgram === ProgramEnum.SPORTDANSLAVILLE
    );

    if (!currentSdlvRegisteredProg) {
      const registeredProg: RegisteredProgram = new RegisteredProgram();
      registeredProg.idProgram = 1;
      currentYear.listOfRegisteredProgram.push(registeredProg);
    }

    return currentYear;
  }

  private static mapEventToRegisterEvent(
    event: SimpleEventListDTO,
    idYear: number
  ): RegisteredEvent {
    return {
      id: 0,
      idEvent: event.id,
      idYears: idYear
    };
  }

  private static desinscriptionEvent(eventToUnsub: SimpleEventListDTO[], year: Year) {
    // Desinscription
    if (eventToUnsub?.length > 0) {
      eventToUnsub.forEach((value) => {
        if (year.listOfRegisteredEvent.find((item) => item.idEvent === value.id)) {
          const indexReg = year.listOfRegisteredEvent.findIndex(
            (regEvent) => regEvent.idEvent === value.id && !regEvent.dateEnd
          );
          if (indexReg > -1) {
            year.listOfRegisteredEvent[indexReg].hasUnsubscribedRegisterEvent = true;
          }
        }
      });
    }
  }

  private static setRelationUserAndRelative(
    family: RelationUserForm[],
    i: number,
    currentUser: User,
    familiesForm: RelationUserForm[]
  ) {
    if (family && family[i]) {
      currentUser.listOfUser1[i].id = familiesForm[i].id;
      currentUser.listOfUser1[i].job = familiesForm[i].job;
      currentUser.listOfUser1[i].idUser = currentUser.id;
      currentUser.listOfUser1[i].idRelative = familiesForm[i].relative.id;
      currentUser.listOfUser1[i].familySituation = Number(familiesForm[i].familySituation);
      currentUser.listOfUser1[i].relationUserEnum = Number(familiesForm[i].relationUserEnum);

      currentUser.listOfUser1[i].relative.id = familiesForm[i].relative.id;
      currentUser.listOfUser1[i].relative.firstName = family[i].relative.firstName;
      currentUser.listOfUser1[i].relative.lastName = family[i].relative.lastName;
      currentUser.listOfUser1[i].relative.email = family[i].relative.email;
      currentUser.listOfUser1[i].relative.isConnected = ConnectedStatusEnum.VALIDATED;

      if (familiesForm[i].relative.phone) {
        currentUser.listOfUser1[i].relative.phone = this.formatPhone(
          PhoneRegionCodeEnum[family[i].relative.phoneCode],
          family[i].relative.phone
        );
      } else {
        currentUser.listOfUser1[i].relative.phone = null;
      }

      if (isNotNullOrUndefined(familiesForm[i].relative.respSex1) && i === 0) {
        currentUser.listOfUser1[i].relative.sex = familiesForm[i].relative.respSex1;
      } else if (isNotNullOrUndefined(familiesForm[i].relative.respSex2) && i === 1) {
        currentUser.listOfUser1[i].relative.sex = familiesForm[i].relative.respSex2;
      } else {
        // Nothing
      }

      if (familiesForm[i].relative.address && familiesForm[i].relative.address.id) {
        currentUser.listOfUser1[i].relative.address.id = familiesForm[i].relative.address.id;
      }
      currentUser.listOfUser1[i].relative.address.address1 = trim(
        familiesForm[i].relative.address.address1
      );
      currentUser.listOfUser1[i].relative.address.address2 = trim(
        familiesForm[i].relative.address.address2
      );
      currentUser.listOfUser1[i].relative.address.zipCode =
        familiesForm[i].relative.address.zipCode;
      currentUser.listOfUser1[i].relative.address.city = trim(
        familiesForm[i].relative.address.city
      );
    }
  }

  private static createRelationUser(): RelationUser {
    const relationUser = new RelationUser();
    relationUser.relative = new User();
    relationUser.relative.address = new Address();
    return relationUser;
  }

  private static initResp(
    resp1: RelationUserForm,
    resp2: RelationUserForm,
    family: RelationUserForm[],
    currentUser: User
  ) {
    if (resp1) {
      family.push(resp1);
      currentUser.listOfUser1[0] = this.createRelationUser();
    }

    if (resp2) {
      family.push(resp2);
      currentUser.listOfUser1[1] = this.createRelationUser();
    }
  }

  private static flattenMap(map: Map<number, Event[]>): SimpleEventListDTO[] {
    let newArray = [];
    map.forEach((value: Event[]) => {
      newArray = newArray.concat(...value);
    });

    return newArray;
  }
}
