import {Jobbeur} from './jobbeur.model';
import {PTravel} from '../param/p-travel.model';

export class JobbeurTravel {
  idJobbeur: number;
  idPTravel: number;

  jobbeur: Jobbeur;
  pTravel: PTravel;
}
