import {PDomaineMetier} from '../param/p-domaine-metier.model';
import {Jobbeur} from './jobbeur.model';

export class JobbeurPDomaine {
  id: number;
  idJobbeur: number;
  idPDomaineMetier: number;

  jobbeur: Jobbeur;
  pDomaineMetier: PDomaineMetier;
}
