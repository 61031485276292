export enum EligibilityFseEnum {
  NOT_ELIGIBLE,
  POTENTIALLY_ELIGIBLE,
  ALMOST_ELIGIBLE,
  ELIGIBLE
}

export namespace EligibilityFseEnum {
  export function format(eligibilityFse: number): string {
    switch (EligibilityFseEnum[eligibilityFse]) {
      case 'NOT_ELIGIBLE':
        return 'Non éligible';
      case 'POTENTIALLY_ELIGIBLE':
        return 'Potentiellement éligible';
      case 'ALMOST_ELIGIBLE':
        return 'Presque éligible';
      case 'ELIGIBLE':
        return 'Eligible';
      default:
        return '';
    }
  }
}
