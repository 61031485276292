<div [formGroup]="formGroupAttestation" class="dialog-attestation">
  <p class="h5" mat-dialog-title>Attestation de santé pour l’année {{currentYear}}</p>
  <mat-dialog-content class="mat-typography">
    <ng-container *ngIf="isMinor">
      <div class="d-flex flex-row mt-2">
        <mat-checkbox color="primary" formControlName="signAttestation"></mat-checkbox>
        <p>Dans le cadre de l’inscription à Sport dans la Ville de mon enfant <strong>{{youngName}}</strong>, je
          soussigné, <strong>{{legalResponsibleName }}, </strong>en ma qualité de représentant légal, atteste sur
          l’honneur avoir répondu négativement à toutes les rubriques du Questionnaire de santé ci-dessus (QS-SPORT).
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="!isMinor">
      <div class="d-flex flex-row mt-2">
        <mat-checkbox color="primary" formControlName="signAttestation"></mat-checkbox>
        <p>Dans le cadre de mon inscription à Sport dans la Ville, je soussigné, <strong>{{youngName}}</strong> atteste
          sur l’honneur avoir répondu négativement à toutes les rubriques du Questionnaire de santé ci-dessus
          (QS-SPORT).</p>
      </div>
    </ng-container>
    <br/>

    <div class="form-group">
      <span class="label-info spanForm" style="margin-top: 1.2rem">Date </span>
      <mat-form-field class="col-12 py-0 mt-1 mat-form-field-date">
        <input [matDatepicker]="dateAttestation" autocomplete="off" formControlName="dateAttestation" matInput>
        <mat-datepicker-toggle [for]="dateAttestation" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #dateAttestation></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-group">
      <app-input-text [control]="fAttestation.place"
                      [group]="formGroupAttestation"
                      [required]=true
                      fcName="placeAttestation"
                      textLabel="Lieu"></app-input-text>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button (click)="validAttestation()" [disabled]="formGroupAttestation.invalid || !this.fAttestation.signAttestation.value" class="btn btn-primary btn-lg btn-block mt-3"
            mat-button>Valider
    </button>
  </mat-dialog-actions>

</div>
