import {PMobility} from '../param/p-mobility.model';
import {Young} from './young.model';

export class YoungMobility {
  idYoung: number;
  idPMobility: number;

  young: Young;
  pMobility: PMobility;
}
