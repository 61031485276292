import {Jobbeur} from './jobbeur.model';
import {PProject} from '../param/p-project.model';

export class JobbeurProject {
  idJobbeur: number;
  idPProject: number;

  jobbeur: Jobbeur;
  pProject: PProject;
}
