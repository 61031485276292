import {User} from '../../user/user.model';
import {Place} from './place.model';
import {Event} from '../event.model';
import {PriorityDistrictZoningEnum} from '../../enums/priority-district-zoning.enum';
import {Intervenant} from '../../user/intervenant.model';

export class Address {
  id: number;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
  dateUpdate: Date;
  priorityDistrictZoning?: PriorityDistrictZoningEnum;
  commentPriorityDistrictZoning: string;

  users: User[];
  places: Place[];
  events: Event[];
  listOfIntervenant1: Intervenant[];
  listOfIntervenant2: Intervenant[];
}
