<div class="h-100 d-flex flex-fill grid-family">
  <ag-grid-angular #agGrid
                   (gridReady)="onGridReady($event)"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   [animateRows]="true"
                   [columnDefs]="columnYoungDefs"
                   [context]="context"

                   [gridOptions]="gridYoungOption"
                   [localeText]="langue"
                   [paginationPageSize]="paginationSize"
                   [pagination]="true"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [suppressDragLeaveHidesColumns]="true"
                   class="ag-theme-balham flex-fill">
  </ag-grid-angular>
</div>

