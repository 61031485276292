import {Parcours} from './parcours.model';

export class Booster {
  id: number;
  idParcours: number;
  isActif: boolean;
  dateBegin: Date;
  dateEnd?: Date;

  parcours: Parcours;
}
