import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {User} from '../../../../model/user/user.model';
import {GridFamilyComponent} from '../../ag-grid/grid-family/grid-family.component';

@Component({
  selector: 'app-dialog-select-young-creation-family',
  templateUrl: './dialog-select-young-creation-family.component.html',
  styleUrls: ['./dialog-select-young-creation-family.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, GridFamilyComponent, MatDialogActions, MatDialogContent]
})
export class DialogSelectYoungCreationFamilyComponent implements OnInit, OnDestroy {
  result;
  currentResult;
  abort = true;

  families: User[] = [];
  needResp1: boolean;
  needResp2: boolean;

  constructor(public dialogRef: MatDialogRef<DialogSelectYoungCreationFamilyComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    if (!this.families || this.families.length === 0) {
      this.dialogRef.close();
    }
  }

  onRadioChange(event) {
    this.currentResult = {
      tutor1:
        event && event.family1
          ? this.families.find((value) => value.id === event.family1)
          : undefined,
      tutor2:
        event && event.family2
          ? this.families.find((value) => value.id === event.family2)
          : undefined
    };
  }

  async exit(skip = false) {
    if (skip) {
      this.currentResult = undefined;
    }
    this.result = this.currentResult;
    this.abort = false;

    this.dialogRef.close();
  }

  abortAction() {
    this.abort = true;
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // Exit without choose young or create new
    if (this.abort) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.result ? this.result : {});
    }
  }
}
