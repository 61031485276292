import {Jobbeur} from './jobbeur.model';
import {PFinancingMechanism} from '../param/p-financing-mechanism.model';

export class JobbeurFinancingMechanism {
  id: number;
  idJobbeur: number;
  idPFinancingMechanism: number;

  jobbeur: Jobbeur;
  pFinancingMechanism: PFinancingMechanism;
}
