import {User} from './user.model';
import {RelationUserEnum} from '../enums/relation-user.enum';
import {FamilySituationEnum} from '../enums/family-situation.enum';

export class RelationUser {
  id: number;
  idUser?: number;
  idRelative?: number;
  relationUserEnum?: RelationUserEnum;
  familySituation?: FamilySituationEnum;
  dateCreate?: Date;
  dateUpdate?: Date;
  job?: string;
  dateBegin?: Date;
  dateEnd?: Date;

  // entreprise
  dateDebutBinome?: Date;
  dateFinBinome?: Date;
  commentairesFin?: string;
  type?: number;
  dateSignatureContrat?: Date;
  dateSignaturePlan?: Date;
  //end entreprise

  user?: User;
  relative?: User;

  static assign(family: RelationUser, familyInput: RelationUser) {
    Object.assign(family, familyInput);
  }
}
