import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {UserWebservice} from '../../../../services/webservices/user.webservice';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-confirm-delete-user-sdlv',
  templateUrl: './dialog-confirm-delete-user-sdlv.component.html',
  styleUrls: ['./dialog-confirm-delete-user-sdlv.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogTitle]
})
export class DialogConfirmDeleteUserSdlvComponent implements OnDestroy {
  result;

  idUser: number;
  title: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmDeleteUserSdlvComponent>,
    private readonly userWS: UserWebservice,
    private readonly router: Router
  ) {}

  Fermer() {
    this.result = true;
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }

  suppressionTotale() {
    this.userWS.deleteUser(this.idUser, true).subscribe({
      next: (res) => {
        this.result = true;
        this.dialogRef.close();
        this.router.navigate(['profile-setting']);
      },
      error: (err) => {
        this.result = false;
        this.dialogRef.close();
      }
    });
  }

  suppressionAnonymisee() {
    this.userWS.deleteUser(this.idUser, false).subscribe({
      next: (res) => {
        this.result = true;
        this.dialogRef.close();
        this.router.navigate(['profile-setting']);
      },
      error: (err) => {
        this.result = false;
        this.dialogRef.close();
      }
    });
  }
}
