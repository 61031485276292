import {PPillar} from '../param/p-pillar.model';
import {Jobbeur} from './jobbeur.model';

export class PillarJobbeur {
  idJobbeur: number;
  idPPillar: number;

  jobbeur: Jobbeur;
  pPillar: PPillar;
}
